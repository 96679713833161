<template>
  <div class="kbrikl-map">
    <v-card class="px-0 pb-0 rounded-lg" style="overflow: hidden;z-index:1;" flat>
      <div v-if="!map.show" class="d-flex align-center justify-center fill-width" :style="{ height: 'calc(100vh - 140px)' }">
        <v-progress-circular
          :size="50"
          :width="4"
          color="primary"
          indeterminate
        />
      </div>
      <v-card-text v-else class="pa-0" :style="{ height: 'calc(100vh - 140px)' }">
        <l-map
          :zoom="map.zoom"
          :center="map.center"
          style="height: 100%; width: 100%"
        >
          <l-control-fullscreen />
          <l-tile-layer
            :url="map.url"
            :attribution="map.attribution"
          />
          <l-geo-json
            v-if="map.show"
            :geojson="geojson"
            :options="options"
            :options-style="styleFunction"
          />
        </l-map>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import LControlFullscreen from 'vue2-leaflet-fullscreen'

export default {
  name: 'MyAwesomeMap',
  props: {
    latlng: {
      type: Array,
      default: () => []
    }
  },
  components: {
    LControlFullscreen,
    LMap,
    LTileLayer,
    LGeoJson
  },
  data: () => ({
    map: {
      show: false,
      zoom: 6.2,
      center: L.latLng(3.9274408, 108.0457265),
      fillColor: '#00ffff',
      url: 'https://tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
      currentZoom: 6.2,
      currentCenter: L.latLng(3.9274408, 108.0457265),
      mapOptions: {
        zoomSnap: 6.2
      }
    },
    geojson: null
  }),
  watch: {
    // 'map.show': function (v) {
    //   if (v) {
    //     if (this.$store.state.map.setPoint.data) {
    //       this.map.center = L.latLng(this.$store.state.map.setPoint.data[0], this.$store.state.map.setPoint.data[1])
    //       this.map.currentCenter = L.latLng(this.$store.state.map.setPoint.data[0], this.$store.state.map.setPoint.data[1])
    //     } else if (this.latlng.length) {
    //       this.map.center = L.latLng(this.latlng[0], this.latlng[1])
    //       this.map.currentCenter = L.latLng(this.latlng[0], this.latlng[1])
    //     } else {
    //       this.map.center = L.latLng(4.0669396, 108.2223088)
    //       this.map.currentCenter = L.latLng(4.0669396, 108.2223088)
    //     }
    //     setTimeout(() => {
    //       this.drawMap()
    //     }, 300)
    //   } else {
    //     this.map.show = false
    //   }
    // }
  },
  computed: {
    options () {
      return {
        onEachFeature: this.onEachFeatureFunction
      }
    },
    styleFunction () {
      const fillColor = this.map.fillColor
      return (feature) => {
        return {
          weight: 1,
          color: feature.properties.color || fillColor,
          opacity: 0.5,
          fillColor: feature.properties.color || fillColor,
          fillOpacity: 0.5
        }
      }
    },
    onEachFeatureFunction () {
      return (feature, layer) => {
        layer.bindTooltip(
          '<div class="font-weight-bold">' + feature.properties.name + '</div><div>Total: ' + (parseInt(feature.properties.total) || 0) + '</div>',
          { permanent: false, sticky: true }
        )
      }
    }
  },
  async created () {
    this.map.show = false
    const prepared = {
      type: 'FeatureCollection',
      features: []
    }
    await this.$store.dispatch('KBRI_AREA_MAP_GET').then((res) => {
      if (res.status) {
        const data = res.data.data
        for (let m = 0; m < data.length; m++) {
          if (m <= 17) {
            const d = data[m]
            const dataSource = this.$StrToJson(d.data_source)
            // this.$toGeoJSON(dataSource, (d) => {})
            if (dataSource) {
              if (dataSource.features) {
                prepared.features.push(Object.assign({}, {
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates: dataSource.features[0].geometry.rings
                  },
                  properties: {
                    name: d.name,
                    color: parseInt(d.total) > 0 ? '#0000CD' : '',
                    total: d.total
                  }
                }))
                // const attr = dataSource.find(() => r)
              }
            }
          }
        }
      }
      this.geojson = Object.assign({}, prepared)
      setTimeout(() => {
        this.map.show = true
      }, 1000)
    })
  },
  destroyed () {
    this.map.show = false
  },
  methods: {
    ReloadMap () {
      const trxID = parseInt(this.$store.state.map.data.id) || 0
      if (trxID) {
        this.$store.dispatch('trx/TRX_GET_ONE', trxID)
          .then((res) => {
            if (res.status) {
              this.$store.commit('MAP', {
                show: true,
                setPoint: {
                  active: true,
                  data: (res.data.data.driver_lat && res.data.data.driver_lng ? [parseFloat(res.data.data.driver_lat), parseFloat(res.data.data.driver_lng)] : null)
                },
                data: Object.assign({}, this.$store.state.map.data)
              })
            } else {
              this.$store.dispatch('TOAST', { show: true, message: 'Terjadi kesalahan!' })
            }
          })
      } else {
        this.$store.dispatch('TOAST', { show: true, message: 'Invalid Transaction ID!' })
      }
    },
    getPlaceData (v) {
      if (v.route) {
        this.$store.commit('MAP', {
          show: true,
          setPoint: {
            active: true,
            draggable: true,
            data: [v.latitude, v.longitude]
          },
          data: null
        })
        this.map.center = L.latLng(v.latitude, v.longitude)
      }
    },
    MapReady () {
      console.log('ready')
    },
    SetLatLong (lat, long) {
      return L.latLng(lat, long)
    },
    drawMap () {
      this.map.show = true
    },
    zoomUpdate (zoom) {
      this.map.currentZoom = zoom
    },
    centerUpdate (center) {
      this.map.currentCenter = center
    },
    boundsUpdate (bounds) {
    },
    setPointCoordinates (latlng) {
      const s = document.getElementById('mapSearch')
      if (s) {
        s.value = ''
      }
      this.$store.commit('MAP', {
        show: true,
        setPoint: {
          active: true,
          draggable: true,
          data: [latlng.lat, latlng.lng]
        },
        data: null
      })
      this.map.center = latlng
    }
  }
}
</script>

<style lang="scss">
// .kbrikl-map {
//   min-height: calc(100vh - 200px);
// }
#mapSearch {
  display: block;
  width: 100%;
  padding: 5px 15px;
  outline: none;
}
</style>
